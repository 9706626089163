<template>
  <div class="m-auto end-page lg:bg-primary base__line max-w-screen-xxl">
    <div class="h-full m-auto lg:min-h-screen container__op lg:flex lg:justify-center">
      <div class="text-white bg-primary base__line lg:w-1/2 lg:my-0 lg:flex lg:bg-transparent">
        <img
          width="60"
          height="60"
          class="absolute top-0 left-0 right-0 pt-4 m-auto lg:hidden"
          src="../assets/numberly__logo__mobile.png"
          alt
          srcset
        />
        <section
          class="flex flex-col justify-center h-full py-32 mx-8 lg:h-auto lg:p-0 lg:min-h-100vh"
        >
          <h2 class="text-xl align-middle">base line of op</h2>
          <h3 class="text-lg">lorem ipsum is simply dummy text of the printing and typesetting</h3>
        </section>
      </div>
      <section
        class="w-11/12 min-h-full m-5 mx-auto lg:min-h-100vh form lg:w-112 lg:bg-white lg:px-10 lg:m-0"
      >
        <img
          width="150"
          height="100"
          class="hidden py-10 m-auto lg:block"
          src="../assets/numberly__logo__desktop.png"
          alt
          srcset
        />
        <div class="my-10 font-thin text-center">
          <h2 class="text-xl">{{ $t('end.title') }}</h2>
          <hr class="w-1/3 m-auto my-6" />
          <p v-html="$t('diffusion.subtitle')"></p>
        </div>
        
        <span class="table m-auto">
          <NCta
            :borderRadius="panoplyStyle.cta.rounded ? '30px': '0px'"
            :backgroundColor="{default: colorStyle.primary, hover: 'white'}"
            :textColor="{default: 'white', hover: colorStyle.primary}"
            :borderColor="{ default: 'white', hover: colorStyle.primary }"
            type="a"
            @click.native="exitUrl(1)"
            v-if="$te('diffusion.share.summary')"
          >{{ $t('end.redirection') }}</NCta>
        </span>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import design from '@/validators/design'

export default {
  name: 'EndPage',
  data () {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
    }
  },
  methods: {
    ...mapActions(['updateModalConfig']),
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    }
  },
  computed: {
    ...mapGetters({
      idu: 'global/idu',
      token : 'global/token',
      visitId :'global/visitId',
      appConfig: 'config/appConfig'
    }),
  },
}
</script>


<style lang="scss" scoped>
</style>
